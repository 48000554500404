<!--管理员平台-->
<template>
    <div class="common">
        <!--顶部-->
        <el-row type="flex" justify="space-between" align="middle" class="commonTop" :style="{'left':isCollapse?'64px':'200px'}">
            <el-row type="flex" align="middle" class="name">
                <router-link :to="{ name: '引导页'}">订货商城后台管理系统</router-link>
                <audio id="myAudio" muted>
                    <source src="../assets/voice1.mp3" type="audio/mpeg">
                    您的浏览器不支持 audio 元素。
                </audio>
                <button id="kaiqi" @click="start()" style="display:none">开启</button>
            </el-row>
            <el-row type="flex" justify="space-between" align="middle" class="nav">
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-s-help"></i>
                        <span style="margin-left:5px">公众号</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/gzh.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-shopping-bag-1"></i><span style="margin-left:5px">小程序</span><i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/codes.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <router-link v-if="adminInfo.role_id == 1" :to="{ name: '消息提醒'}">
                    <div class="msg flex flex-ai-c" style="cursor: pointer;">
                        <p>消息通知</p>
                        <el-badge :value="$root.auditCount.count == 0 ? '' : $root.auditCount.count" class="item">
                            <img src="../assets/laba2.png" alt="">
                        </el-badge>
                    </div>
                </router-link>
                <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{adminInfo.username}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- v-if="$permission.is('/juese')" -->
                            <el-dropdown-item v-if="adminInfo.role_id == 1" icon="el-icon-s-tools" command="setting" @click.native="goSet()">后台管理
                            </el-dropdown-item>
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="loginOut()">退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
                <!-- <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{adminInfo.username}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="loginOut()">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row> -->
            </el-row>
        </el-row>

        <!--左侧菜单-->
        <el-row class="commonLeft" :style="{'width':isCollapse?'64px':'200px'}">
            <el-row class="nav">
                <el-aside>
                    <el-row type="flex" justify="center" class="headImg">
                        <img :src="require('../assets/avatars.jpg')" :style="{'width':isCollapse?'42px':'60px'}" />
                        <p v-if="!isCollapse">{{adminInfo.username}}</p>
                    </el-row>
                    <el-menu background-color="#001529" text-color="#EBF2FF" :default-active="$route.path" :unique-opened="true" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 184px );overflow-y: auto;overflow-x: hidden;">
                        <template v-for="nav in navList2">
                            <el-menu-item :index="nav.path" :key="nav.name" _click="navTo(nav.path)" v-if="!nav.sub && $permission.is(nav.apiurllist)">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span slot="title">{{nav.name}}</span>
                                <el-badge v-if="nav.name == '提现管理'" style="top: -10px;" :value="withdraw_need_audit  == 0 ? '' : withdraw_need_audit" class="item" slot="title"></el-badge>
                            </el-menu-item>

                            <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub && $permission.is(nav.apiurllist)">
                                <template slot="title">
                                    <i :class="nav.icon" v-if="nav.icon"></i>
                                    <span>{{nav.name}}</span>
                                </template>
                                <template v-for="item in nav.sub">
                                    <el-menu-item :index="item.path" :key="item.name" v-if="!item.sub && $permission.is(item.apiurllist)">
                                        <i :class="item.icon" v-if="item.icon"></i>
                                        {{item.name}}
                                    </el-menu-item>

                                    <el-submenu :index="item.path" :key="item.name" v-if="item.sub && $permission.is(item.apiurllist)">
                                        <template slot="title">
                                            <i :class="item.icon" v-if="item.icon"></i>
                                            <span>{{item.name}}</span>
                                        </template>
                                        <el-menu-item v-for="item2 in item.sub" :index="item2.path" :key="item2.name">
                                            <i :class="item2.icon" v-if="item2.icon"></i>
                                            {{item2.name}}
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                            </el-submenu>
                        </template>
                    </el-menu>
                    <el-row class="menuBtnbox" :style="{'width':isCollapse?'64px':'200px'}">
                        <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                    </el-row>
                </el-aside>
            </el-row>
        </el-row>

        <!--主要内容-->
        <el-row class="commonRight" :style="{'left':isCollapse?'64px':'200px'}">
            <router-view :isCollapse="isCollapse" />
        </el-row>
    </div>
</template>

<script>
import { backPlatformApi } from '@/api/house.js';
import axios from 'axios';
import canAutoPlay from 'can-autoplay';
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
export default {
    data: function () {
        return {
            defaultActive: this.$route.path,
            isCollapse: false,
            transitionName: '',
            adminInfo: {},
            timer: null,
            withdraw_need_audit:0,
            navList2: [{
                name: '统计概况',
                path: '/overview',
                icon: 'el-icon-s-home',
                apiurllist: '/overview',
            }, {
                name: '审核管理',
                path: '/cashAudit',
                icon: 'el-icon-s-check',
                apiurllist: '/cashAudit',
            }, {
                name: '会员管理',
                path: '/CategoryMemberList',
                icon: 'el-icon-s-tools',
                apiurllist: '/CategoryMemberList'
            }, {
                name: '商品管理',
                path: '/shopList',
                icon: 'el-icon-s-goods',
                apiurllist: '/shopManage',
                sub: [{
                    name: '普通商品列表',
                    path: '/shop',
                    apiurllist: '/shop',
                }, {
                    name: '会员商品列表',
                    path: '/membershop',
                    apiurllist: '/membershop',
                }, {
                    name: '预售商品列表',
                    path: '/ysalesList',
                    apiurllist: '/ysalesList',
                }, {
                    name: '积分兑换列表',
                    path: '/PointChangeList',
                    apiurllist: '/PointChangeList',
                }, {
                    name: '商品规格',
                    path: '/shopSpec',
                    apiurllist: '/shopSpec',
                }, {
                    name: '商品分类',
                    path: '/shopType',
                    apiurllist: '/shopType',
                }, {
                    name: '商品场景',
                    path: '/shopScene',
                    apiurllist: '/shopScene',
                }]
            }, {
                name: '订单管理',
                path: '/order',
                icon: 'el-icon-s-order',
                apiurllist: '/orderManage',
                sub: [{
                    name: '商品订单',
                    path: '/order',
                    apiurllist: '/order',
                }, {
                    name: '预售订单',
                    path: '/ysales',
                    apiurllist: '/ysales',
                }, {
                    name: '积分兑换订单',
                    path: '/PointChangeOrder',
                    apiurllist: '/PointChangeOrder',
                }]
            },
            {
                name: '会员订单',
                path: '/virtualBuyOrder',
                icon: 'el-icon-s-order',
                apiurllist: '/memberManage',
                sub: [{
                    name: '购买订单',
                    path: '/virtualBuyOrder',
                    apiurllist: '/virtualBuyOrder',
                }, {
                    name: '续费订单',
                    path: '/reneworder',
                    apiurllist: '/reneworder',
                }]
            },
            {
                name: '员工管理',
                path: '/staffManage',
                icon: 'el-icon-user-solid',
                apiurllist: '/staff',
                sub: [{
                    name: '业务员',
                    path: '/staffManage',
                    apiurllist: '/staffManage',
                }, {
                    name: '市场部',
                    path: '/businessIndex',
                    apiurllist: '/businessIndex',
                }]
            },
            // {
            //     name: '员工管理',
            //     path: '/staffManage',
            //     icon: 'el-icon-user-solid',
            //     apiurllist: '/staffManage'
            // },
            {
                name: '用户管理',
                path: '/ordinaryUser',
                icon: 'el-icon-s-custom',
                apiurllist: '/userManage',
                sub: [{
                    name: '普通用户',
                    path: '/ordinaryUser',
                    apiurllist: '/ordinaryUser',
                }, {
                    name: '会员用户',
                    path: '/memberUser',
                    apiurllist: '/memberUser',
                }]
            },
            {
                name: '配置管理',
                path: '/config',
                icon: 'el-icon-set-up',
                apiurllist: '/configManage',
                sub: [{
                    name: '基本配置',
                    path: '/config',
                    apiurllist: '/config',
                }, {
                    name: '优惠券',
                    path: '/coupon',
                    apiurllist: '/coupon',
                }, {
                    name: '充值配置',
                    path: '/reCharge',
                    apiurllist: '/reCharge',
                }, {
                    name: '轮播大图',
                    path: '/banner',
                    apiurllist: '/banner',
                },
                {
                    name: '公告管理',
                    path: '/notice',
                    apiurllist: '/notice',
                }
                ]
            },
            {
                name: '续费提醒',
                path: '/message',
                icon: 'el-icon-s-tools',
                apiurllist: '/message'
            }, {
                name: '提现管理',
                path: '/cash',
                icon: 'el-icon-money',
                apiurllist: '/cash'
            }]
        }
    },
    //使用watch 监听$router的变化
    watch: {
        $route(to, from) {
            axios.post(window.domain + '/admin.statistics/news').then(response => {
                if (response.data.code == 200) this.$root.auditCount = response.data.data
            })
            this.http.post('/admin.admin/loginInfo').then(response => {
                this.$root.userInfo = response;
            })
        }
    },

    //导航守卫
    beforeRouteEnter(to, from, next) {
        // Promise.all 會等到數組內的 Promise 都 resolve 後才會繼續跑(then)
        Promise.all([
            axios.post(window.domain + '/admin.statistics/news'),
            axios.post(window.domain + '/admin.admin/loginInfo')
        ]).then(result => next(vm => {
            if (result[0].data.code == 200) vm.$root.auditCount = result[0].data.data;
            if (result[1].data.code == 200) vm.$root.userInfo = result[1].data.data;
        }))
    },
    mounted: function () {

        backPlatformApi();
        this.adminInfo = localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : this.adminInfo;
        canAutoPlay.audio().then(result => {
            if (result === true) {
                console.log('can auto play!')
            } else {
                if (this.adminInfo.role_id == 1) {
                    this.$alert(
                        '检测到您的浏览器不支持媒体自动播放，是否同意播放提示音',
                        '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            let vMP3 = document.getElementById("myAudio");
                            vMP3.muted = true;
                            vMP3.play();
                        }
                    })
                }

            }
        })
        this.getOrderCount();
    },
    methods: {
        //开始播报
        start() {
            let vMP3 = document.getElementById("myAudio");
            vMP3.muted = false;
            vMP3.play();
        },
        //暂停播报
        pause: function () {
            let vMP3 = document.getElementById("myAudio");
            vMP3.pause();
        },

        goSet() {
            this.$router.push('/setRole');
        },

        getOrderCount() {
            this.http.post('/admin.statistics/orderReminder', {}, false).then(re => {
                this.withdraw_need_audit = re.withdraw_need_audit;
                if (re.count > 0) {
                    this.start();
                } else {
                    this.pause();
                }
                setTimeout(() => {
                    this.getOrderCount();
                }, 10000)
            })
        },
        // clearHouse: function() {
        //     clearInterval(this.timer);
        //     this.timer = null;
        //     this.pause();
        //     // this.speckPause();
        // },

        //语音播报
        // speckText(str) {
        //     // console.log('语音播报');
        //     // var url = "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=" + encodeURI(str);
        //     // new Audio(url).play();
        //     var url = 'https://speaker.17laimai.cn/notify.php?id=5042108180174&token=HK1635557711&version=3&message=' + str
        //     new Audio(url).play()
        // },

        //nav跳转
        navTo: function (path) {
            this.$router.push(path);
        },

        handleCollapse: function () {
            this.isCollapse = !this.isCollapse;
        },

        // 退出登录
        loginOut: function () {
            this.http.post("/login/logout").then(() => {
                localStorage.clear();
                this.$router.push("/");
            });
        }
    }
}
</script>
<style media="screen">
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all 500ms;
    position: absolute;
}

.slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 200);
}

.slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 200);
}
</style>
